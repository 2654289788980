import React, { useState } from 'react';
import './App.css';
import { Button, TextField } from '@mui/material';
import AudioPlayer from 'react-modern-audio-player';
import jsonp from 'jsonp';

function App() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubscribe = (e) => {
        e.preventDefault();
        if (!email) {
            setMessage('Please enter a valid email address.');
            return;
        }


        const url = 'https://voxmata.us19.list-manage.com/subscribe/post-json?u=f430c318ab255487437e50aac&amp;id=ee50839276&EMAIL=' + encodeURIComponent(email);
        console.log(url);

        jsonp(url, { param: 'c' }, (err, data) => {
            if (err) {
                setMessage('An error occurred. Please try again.');
            } else if (data.result !== 'success') {
                setMessage(data.msg);
            } else {
                setMessage('Thank you for subscribing!');
                setEmail('');
            }
        });
    };

    const playList = [
        {
            name: 'Trials of the Fyat',
            writer: 'Fyat Lux',
            img: 'voxmata.png',
            src: '/trialsofthefyat.wav',
            id: 1,
        },
        {
            name: 'RWND',
            writer: 'Christian Vázquez',
            img: 'voxmata.png',
            src: '/rwnd(excerpt).wav',
            id: 2,
        },
        {
            name: 'Harry Potter (excerpt)',
            writer: 'J.K. Rowling',
            img: 'voxmata.png',
            src: '/harrypottersample.wav',
            id: 3,
        },
    ];

    return (
        <div className="App">
            <div className="main-content">
                <div className="left-panel">
                    <div className="title grow">voxmata</div>

                    <div className="info grow">
                        <div className="callout">Words are only the beginning</div>
                        <div className="explain">
                            Transform your stories into immersive audio dramas with voiced characters, sound effects, and music with just a click.
                        </div>
                        <div className="grow" style={{ marginTop: '20px' }}>
                            <form className="input-form" onSubmit={handleSubscribe}>
                                <TextField
                                    label="Enter your email"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ marginBottom: '15px' }}
                                />
                                <div className="button-form">
                                <Button
                                    variant="contained"
                                    sx={{
                                        borderRadius: 100,
                                        backgroundColor: 'black',
                                        textTransform: 'none',
                                    }}
                                    type="submit"
                                >
                                    Join waitlist
                                </Button>
                                </div>
                            </form>
                            {message && (
                                <div style={{ marginTop: '15px', color: 'green', textAlign:'left' }}>
                                    {message}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <AudioPlayer
                playList={playList}
                activeUI={{
                    all: true,
                    trackTime: false,
                }}
                placement={{
                    player: 'bottom-left',
                    playList: 'top',
                    interfacePlacement: {
                        artwork: 'row1-2',
                        playList: 'row1-3',
                        trackInfo: 'row2-2',
                        trackTimeCurrent: 'row3-1',
                        progress: 'row3-2',
                        trackTimeDuration: 'row3-3',
                        playButton: 'row4-2',
                        repeatType: 'row4-1',
                        volume: 'row4-3',
                    },
                    playListPlacement: 'top',
                    volumeSliderPlacement: 'left',
                    progressType: 'bar',
                }}
            />
        </div>
    );
}

export default App;
